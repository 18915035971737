import { useQuery } from '@tanstack/react-query';
import { SkyhookExperienceModule } from '@website/pages/api/contentful/skyhook-experience';
import clsx from 'clsx';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { getImageUrl } from '../media/utils/imgix';
import ReviewsSwiper from './ReviewsSwiper';

type Props = {
  isHomepage?: boolean;
};

export default function SkyhookExperience({ isHomepage = false }: Props) {
  const { data, isLoading, error } = useQuery<SkyhookExperienceModule[]>({
    queryKey: ['reviews', 'skyhook-experience'],
    queryFn: async () => {
      const response = await fetch('/api/contentful/skyhook-experience');
      return response.json();
    },
  });

  if (isLoading) {
    return null;
  }

  if (error && !data) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <section
      className={clsx('position-relative mt-4', isHomepage ? 'bg-light' : '')}
    >
      <Container className="py-5 mx-auto">
        {isHomepage ? (
          <Row>
            <Col md="8">
              <p className="subtitle text-primary">
                Our customers say it best!
              </p>
              <h2 className="mb-0">Skyhook experience</h2>
            </Col>
          </Row>
        ) : (
          <div className="text-center h-100">
            <h2>Skyhook Experience</h2>
            <p className="text-center text-lead">Our customers say it best!</p>
          </div>
        )}

        <ReviewsSwiper
          data={data}
          isVideo
          // eslint-disable-next-line @typescript-eslint/no-shadow
          reviewComponent={({ data }) => (
            <Col
              style={{
                width: '100%',
                aspectRatio: '9/16',
              }}
              key={data.youTubeShortsLink}
            >
              <ReactPlayer
                height="100%"
                width="100%"
                url={data.youTubeShortsLink}
                controls
                playing
                playIcon={<></>}
                light={
                  data.thumbnail.url
                    ? getImageUrl(
                        data.thumbnail.url,
                        process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL,
                      )
                    : true
                }
              />
            </Col>
          )}
        />
      </Container>
    </section>
  );
}
