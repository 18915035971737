import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { ContentfulReviewsResponse } from '@website/pages/api/contentful/reviews';
import Link from 'next/link';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaQuoteLeft } from 'react-icons/fa';
import { MdOutlineStar } from 'react-icons/md';
import Avatar from '../directoryTheme/Avatar';
import TrustPilotWidget from '../trustpilot/TrustPilotWidget';
import ReviewsSwiper from './ReviewsSwiper';

type Props = {
  isHomepage?: boolean;
};

export default function ReviewsModule({ isHomepage = false }: Props) {
  const { data, isLoading, error } = useQuery<ContentfulReviewsResponse>({
    queryKey: ['reviews', 'reviewsModuleCollection'],
    queryFn: async () => {
      const response = await fetch('/api/contentful/reviews');
      return response.json();
    },
  });

  const reviewsToLastHundredth = useMemo(() => {
    if (!data) {
      return null;
    }

    return Math.floor(data.reviewStats.reviewCount / 100) * 100;
  }, [data]);

  if (isLoading) {
    return null;
  }

  if (error && !data) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <section className="position-relative mt-4">
      <Container className="py-5">
        {isHomepage ? (
          <Row>
            <Col md="8">
              <p className="subtitle text-primary d-flex">Rated Excellent</p>
              <h2 className="mb-0">Our reviews</h2>
              <p className="d-flex">
                <span>
                  {[1, 2, 3, 4, 5].map((i) => (
                    <MdOutlineStar size={20} key={i} className="text-primary" />
                  ))}
                </span>
                <span className="ms-2 mt-1">
                  4.9/5 ({data.reviewStats.reviewCount.toLocaleString()}{' '}
                  reviews)
                </span>
              </p>
            </Col>
            <Col
              md="4"
              className="d-lg-flex align-items-center justify-content-end"
            >
              <Link href="/reviews" className="text-muted text-sm">
                Read our reviews{' '}
                <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
              </Link>
            </Col>
          </Row>
        ) : (
          <div className="text-center h-100">
            <h2>
              Rated Excellent by over {reviewsToLastHundredth.toLocaleString()}{' '}
              travellers
            </h2>
            <p className="d-flex justify-content-center">
              <span>
                {[1, 2, 3, 4, 5].map((i) => (
                  <MdOutlineStar size={30} key={i} className="text-primary" />
                ))}
              </span>
              <span className="ms-2 mt-1">
                4.9/5 ({data.reviewStats.reviewCount.toLocaleString()} reviews)
              </span>
            </p>
            <p>
              <Link href="/reviews" className="fw-bold">
                Read our reviews
              </Link>
            </p>
          </div>
        )}
        <ReviewsSwiper
          data={data.reviews}
          reviewComponent={({ data: review }) => (
            <Col
              className="w-100 h-100"
              // eslint-disable-next-line no-underscore-dangle
              key={review._id}
            >
              <div
                className="bg-light rounded-3 p-4 text-start d-flex flex-column h-100"
                style={{
                  gap: '1rem',
                }}
              >
                <FaQuoteLeft size={25} />
                <h5
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word',
                  }}
                >
                  {review.subject}
                </h5>
                <p
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word',
                    flex: 1,
                  }}
                >
                  {review.content}
                </p>
                <div className="d-flex">
                  <Avatar
                    alt={review.profilePicture.title}
                    image={review.profilePicture.url}
                    defaultImageText=""
                    cdn={process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL}
                  />
                  <div className="ms-2">
                    <div
                      className="d-block text-muted"
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                    >
                      {review.nameOfTheReview}
                    </div>
                    <Link
                      target="_blank"
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                      }}
                      href={review.linkToTrip}
                    >
                      {review.tripName}
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          )}
        />
      </Container>

      <Container className="pb-5 pt-3 ">
        <p className="text-center fw-bold -mb-2">
          Also see our reviews on Trustpilot
        </p>
        <TrustPilotWidget
          templateId="5419b6ffb0d04a076446a9af"
          height="20px"
          className="mx-auto"
        />
      </Container>
    </section>
  );
}
