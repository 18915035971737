import Imgix, { Background, buildURL } from 'react-imgix';

export function getImageUrl(
  key: string,
  cdn = process.env.NEXT_PUBLIC_APP_IMGIX_URL,
) {
  const keyStripContentful = key.replace(
    `https://images.ctfassets.net/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/`,
    '',
  );

  return `${cdn}/${keyStripContentful}?auto=format`;
}

export function getImageUrlWithParams(
  key: string,
  params: Record<string, string | number | boolean>,
  cdn = process.env.NEXT_PUBLIC_APP_IMGIX_URL,
) {
  const keyStripContentful = key.replace(
    `https://images.ctfassets.net/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/`,
    '',
  );

  return buildURL(`${cdn}/${keyStripContentful}`, params);
}

export function getImageUrlForDownload(
  key: string,
  cdn = process.env.NEXT_PUBLIC_APP_IMGIX_URL,
) {
  const keyStripContentful = key.replace(
    `https://images.ctfassets.net/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/`,
    '',
  );

  return `${cdn}/${keyStripContentful}?fm=jpg&q=90&dl`;
}

/**
 *
 * composeImageURL
 *
 * Compose URL from image key and cdn url, with params
 *
 */
export function composeImageURL(
  key: string,
  params: Record<string, string | number | boolean> = {},
  cdn = process.env.NEXT_PUBLIC_APP_IMGIX_URL,
) {
  const combinedParams: Record<string, string | number | boolean> = {
    auto: 'compress,format,enhance,redeye',
    ...params,
  };
  const imageURL = new URL(key, cdn).href;
  return buildURL(imageURL, combinedParams);
}

/**
 * Background Image
 *
 * @depreciated Do not use as the underlying Imgix component suffers from performance issues whereby it re-renders
 * thousands of times a second.
 */
export function BackgroundImage({
  imgKey,
  imgixParams = undefined,
  children,
  style = undefined,
  className = undefined,
}) {
  const imgUrl = getImageUrl(imgKey);
  return (
    <Background
      imgixParams={{
        auto: 'compress,format,enhance,redeye',
        crop: 'faces',
        ...imgixParams,
      }}
      src={imgUrl}
      className={className}
      htmlAttributes={{
        style: {
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          ...style,
        },
      }}
    >
      {children}
    </Background>
  );
}

export function Image({
  imgKey,
  imgixParams,
  styles,
  className,
  width,
  height,
  alt,
  sizes,
  loading,
  cdn = process.env.NEXT_PUBLIC_APP_IMGIX_URL,
}: ImageProps) {
  const imgUrl = getImageUrl(imgKey, cdn);
  return (
    <Imgix
      imgixParams={{
        auto: 'compress,format,enhance',
        crop: 'faces',
        fit: 'crop',
        ...imgixParams,
      }}
      width={width}
      height={height}
      src={imgUrl}
      sizes={sizes}
      className={className}
      htmlAttributes={{
        style: styles,
        alt,
        loading,
      }}
    />
  );
}

/* eslint-disable react/require-default-props */
interface ImageProps {
  imgKey: string;
  imgixParams?: Record<string, string | number | boolean>;
  styles?: object;
  className?: string;
  width?: number;
  height?: number;
  alt?: string;
  sizes?: string;
  loading?: HTMLImageElement['loading'];
  cdn?: string;
}

export function openGraphImage(
  imgKey: string,
  imgixParams: Record<string, string | number | boolean>,
  cdn?: string,
) {
  const cloudfrontUrl = getImageUrl(imgKey, cdn);
  const imgixUrl = buildURL(cloudfrontUrl, {
    auto: 'compress,format,enhance',
    crop: 'faces',
    fit: 'crop',
    w: '1200',
    h: '630',
    ...imgixParams,
  });
  return imgixUrl;
}
