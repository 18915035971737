import { createAvatar, Options } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-initials-sprites';
import NextImage from 'next/legacy/image';
import { Image } from '../media/utils/imgix';
import imgAvatar from './default-user-avatar.png';

/**
 * Create a default avatar with initials
 *
 * https://avatars.dicebear.com/styles/initials
 */
export function DefaultInitialsAvatar({
  defaultImageText,
  pxSize,
  alt,
  cover,
  backgroundColor,
}: {
  defaultImageText: string;
  pxSize: number;
  alt: string;
  cover?: boolean;
  backgroundColor?: Options['backgroundColor'];
}) {
  const svg = createAvatar(style, {
    seed: defaultImageText,
    backgroundColor,
    dataUri: true,
  });

  return (
    <img
      src={svg}
      className={`rounded-circle ${cover ? 'bg-image' : ''}`}
      width={pxSize}
      height={pxSize}
      alt={alt}
    />
  );
}

export function DefaultUserAvatarImage({
  pxSize,
  alt,
  cover,
}: {
  pxSize: number;
  alt: string;
  cover?: boolean;
  backgroundColor?: Options['backgroundColor'];
}) {
  return (
    <NextImage
      src={imgAvatar}
      className={`rounded-circle ${cover ? 'bg-image' : ''}`}
      width={pxSize}
      height={pxSize}
      alt={alt}
    />
  );
}

export default function Avatar({
  className = '',
  size = 'md',
  image,
  alt,
  border,
  cover,
  defaultImageText,
  defaultBackgroundColor,
  loading = 'lazy',
  cdn = process.env.NEXT_PUBLIC_APP_IMGIX_URL,
}: Props) {
  let pxSize;
  switch (size) {
    case 'xxl':
      pxSize = border ? 144 : 160;
      break;
    case 'xl':
      pxSize = border ? 96 : 112;
      break;
    case 'lg':
      pxSize = border ? 72 : 80;
      break;
    case 'sm':
      pxSize = border ? 32 : 40;
      break;
    case 'xs':
      pxSize = border ? 21 : 28;
      break;
    case 'xxs':
      pxSize = 16;
      break;
    default:
      pxSize = border ? 40 : 48;
  }

  return (
    <div
      style={{ display: 'inline-flex', height: pxSize, alignSelf: 'center' }}
    >
      <div
        className={`avatar ${size ? `avatar-${size}` : ''}  ${className || ''}`}
      >
        <div className="position-relative overflow-hidden rounded-circle h-100 d-flex align-items-center justify-content-center">
          {!image ? (
            <>
              {defaultImageText ? (
                <DefaultInitialsAvatar
                  defaultImageText={defaultImageText}
                  cover={cover}
                  pxSize={pxSize}
                  alt={alt}
                  backgroundColor={defaultBackgroundColor}
                />
              ) : (
                <DefaultUserAvatarImage
                  cover={cover}
                  pxSize={pxSize}
                  alt={alt}
                  backgroundColor={defaultBackgroundColor}
                />
              )}
            </>
          ) : (
            <Image
              imgKey={image}
              className={`rounded-circle ${cover ? 'bg-image' : ''}`}
              width={pxSize}
              height={pxSize}
              alt={alt}
              imgixParams={{
                fit: 'facearea',
                facepad: 4,
                height: pxSize,
                width: pxSize,
                ar: '1:1',
              }}
              loading={loading}
              cdn={cdn}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type Props = {
  className?: string;

  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';

  /**
   * Image key
   */
  image?: any;

  /**
   * Default image text
   *s
   * Will be turned into a two-letter default avatar with https://dicebear.com
   */
  defaultImageText?: string;

  alt?: string;

  border?: boolean;

  cover?: boolean;

  defaultBackgroundColor?: Options['backgroundColor'];

  loading?: HTMLImageElement['loading'];
  cdn?: string;
};
