import clsx from 'clsx';
import React, { useRef } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { useDraggable } from 'react-use-draggable-scroll';
import styles from './ReviewsSwiper.module.scss';

type ReviewsSwiperProps<T> = {
  reviewComponent: React.FC<{
    data?: T;
  }>;
  data: T[];
  isVideo?: boolean;
};

export default function ReviewsSwiper<T>({
  data,
  reviewComponent: ReviewComponent,
  isVideo = false,
}: ReviewsSwiperProps<T>) {
  const ref = useRef();
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
  });

  // Loader
  if (!data) return <Spinner animation="border" />;

  return (
    <Row className="guides-slider mx-n2 pt-3">
      <div
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          scrollSnapType: 'x',
          overscrollBehaviorX: 'contain',
        }}
        className="d-flex scrollbar-hide py-3"
        {...events}
        ref={ref}
      >
        {data.map((slide, index) => (
          <div
            className={clsx(
              'hover-animate me-3 me-lg-4',
              isVideo ? styles.slideVideos : styles.slide,
            )}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <ReviewComponent data={slide} />
          </div>
        ))}
      </div>
    </Row>
  );
}
